.dragBox {
  width: 100%;
  border-radius: 8px;
  border: 2px dotted #ccc;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: #fff;
  justify-content: center;
  cursor: pointer;
  height: 100%;
}
.dndBox {
  width: 100%;
  height: 200px;
}
